<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <!-- <form @submit.prevent="handleSubmit(!v$.$invalid)"> -->
    <div class="grid">
      <div class="col-12">
        <div class="formgrid grid">
          <div v-if="isHideCheckBox === false" class="field col-12 md:col-12">
            <input
              :checked="released_item"
              type="checkbox"
              id="released_item"
              :disabled="editMode"
              @click="OnCheckClick"
            />
            <label for="active">Tampil Semua Item</label>
          </div>
          <div class="field col-12 md:col-12">
            <label>Item </label>
            <AutoComplete
              v-if="released_item"
              ref="item"
              v-model="selectedItem"
              class="w-full"
              input-class="w-full"
              :suggestions="listItems"
              field="name"
              :autofocus="!editMode"
              :disabled="editMode"
              @complete="searchItem"
              @item-select="selectItem"
              @focus="selectAllText('item')"
              @keydown.enter="enterItem"
            >
              <template #item="slotProps">
                <div>
                  <b>{{ slotProps.item.sku }}</b>
                </div>
                <div>{{ slotProps.item.name }}</div>
              </template>
            </AutoComplete>
            <AutoComplete
              v-if="!released_item"
              ref="item"
              v-model="selectedItem"
              class="w-full"
              input-class="w-full"
              placeholder="Search here"
              :suggestions="listItemSuppliers"
              field="name"
              :autofocus="!editMode"
              :focused="!editMode"
              :disabled="editMode"
              @complete="searchItemSuppliers"
              @item-select="selectItem"
              @focus="selectAllText('item')"
              @keydown.enter="enterItem"
            >
              <template #item="slotProps">
                <div>
                  <b>{{ slotProps.item.sku }}</b>
                </div>
                <div>{{ slotProps.item.name }}</div>
              </template>
            </AutoComplete>
            <small
              v-if="
                (v$.form.item_id.$invalid && submitted) ||
                v$.form.item_id.$pending.$response
              "
              class="p-error"
              >{{ v$.form.item_id.required.$message }}</small
            >
          </div>
          <div class="field col-5 md:col-5">
            <label>Satuan Besar</label>
            <Dropdown
              v-model="form.unit_b"
              :filter="true"
              :options="listSatuan"
              class="w-full"
              input-class="w-full"
              optionValue="sat"
              optionLabel="sat"
              placeholder="Satuan"
              :filter-fields="['sat', 'name', 'qty']"
              @change="inputSatuan"
              :disabled="(onMenu === 'retur' && editMode) || onMenu === 'order'"
            >
              <template #option="slotProps">
                <div>
                  <table class="" style="width: 100%">
                    <tr>
                      <th>{{ slotProps.option.sat }}</th>
                      <th class="text-right">QTY</th>
                    </tr>
                    <tr>
                      <td>{{ slotProps.option.name }}</td>
                      <td class="text-right">{{ slotProps.option.qty }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col-2 md:col-2">
            <label>Isi</label>
            <InputText
              v-model="form.ubox"
              class="w-full"
              disabled
              input-class="w-full"
            />
          </div>
          <div class="field col-5 md:col-5">
            <label>Satuan Kecil</label>
            <Dropdown
              v-model="form.unit_s"
              :filter="true"
              :options="listSatuan"
              class="w-full"
              input-class="w-full"
              optionValue="sat"
              optionLabel="sat"
              placeholder="Satuan"
              :filter-fields="['sat', 'name', 'qty']"
              @change="inputSatuan"
              :disabled="(onMenu === 'retur' && editMode) || onMenu === 'order'"
            >
              <template #option="slotProps">
                <div>
                  <table class="" style="width: 100%">
                    <tr>
                      <th>{{ slotProps.option.sat }}</th>
                      <th class="text-right">QTY</th>
                    </tr>
                    <tr>
                      <td>{{ slotProps.option.name }}</td>
                      <td class="text-right">{{ slotProps.option.qty }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col-6 md:col-6">
            <label>qty (b)</label>
            <InputNumber
              ref="qty_b"
              v-model="form.qty_b"
              :class="{ 'p-invalid': v$.form.qty_b.$invalid && submitted }"
              class="w-full"
              :min="0"
              :max="maxQtyB"
              locale="id-ID"
              input-class="w-full"
              @focus="selectAllText('qty_b')"
              @keydown.enter="enterQtyB"
              :focused="editMode"
              :autofocus="editMode"
            />
            <small
              v-if="
                (v$.form.qty_b.$invalid && submitted) ||
                v$.form.qty_b.$pending.$response
              "
              class="p-error"
            >
              <span v-if="v$.form.qty_b.required.$invalid">
                {{ v$.form.qty_b.required.$message }}
              </span>
              <!-- <span v-else> {{ v$.form.qty_b.minValue.$message }}</span> -->
            </small>
          </div>
          <div class="field col-6 md:col-6">
            <label>qty (s)</label>
            <InputNumber
              ref="qty_s"
              v-model="form.qty_s"
              :class="{ 'p-invalid': v$.form.qty_s.$invalid && submitted }"
              class="w-full"
              :min="0"
              :max="maxQtyS"
              locale="id-ID"
              input-class="w-full"
              @update:model-value="changeQTY"
              @focus="selectAllText('qty_s')"
              @keydown.enter="enterQtyS"
              :autofocus="
                (onMenu === 'receive' || onMenu === 'retur') && editMode
              "
            />
            <small
              v-if="
                (v$.form.qty_s.$invalid && submitted) ||
                v$.form.qty_s.$pending.$response
              "
              class="p-error"
            >
              <span v-if="v$.form.qty_s.required.$invalid">
                {{ v$.form.qty_s.required.$message }}
              </span>
              <!-- <span v-else> {{ v$.form.qty_s.minValue.$message }}</span> -->
            </small>
          </div>
          <div class="field col-6 md:col-6">
            <label>Harga satuan</label>
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon"> Rp </span>
              <InputNumber
                ref="cost"
                v-model="form.cost"
                :class="{
                  'p-invalid': v$.form.cost.$invalid && submitted,
                }"
                locale="id-ID"
                @focus="selectAllText('cost')"
                @keydown.enter="enterCost"
                :min="0"
                class="w-full"
                input-class="w-full"
                :disabled="onMenu === 'retur' && editMode"
              />
            </div>
            <small
              v-if="
                (v$.form.cost.$invalid && submitted) ||
                v$.form.cost.$pending.$response
              "
              class="p-error"
            >
              <span v-if="v$.form.cost.required.$invalid">
                {{ v$.form.cost.required.$message }}
              </span>
              <span v-else> {{ v$.form.cost.numeric.$message }}</span>
            </small>
          </div>
          <div class="field col-6 md:col-6">
            <label>PER</label>
            <Dropdown
              v-model="form.cost_per"
              ref="cost_per"
              :filter="true"
              :options="listSatuanItem"
              class="w-full"
              input-class="w-full"
              optionValue="sat"
              optionLabel="sat"
              placeholder="Satuan"
              :filter-fields="['sat', 'name', 'qty']"
              @change="inputSatuan"
              @keydown.enter="enterCostPer"
              :disabled="onMenu === 'retur'"
            >
              <template #option="slotProps">
                <div>
                  <table class="" style="width: 100%">
                    <tr>
                      <th>{{ slotProps.option.sat }}</th>
                      <th class="text-right">QTY</th>
                    </tr>
                    <tr>
                      <td>{{ slotProps.option.name }}</td>
                      <td class="text-right">{{ slotProps.option.qty }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col-4 md:col-4" v-if="onMenu === 'receive'">
            <label>PPN</label>
            <div class="p-inputgroup flex-1">
              <InputNumber
                ref="tax"
                v-model="form.tax"
                locale="id-ID"
                :min="0"
                :max="100"
                :minFractionDigits="0"
                :max-fraction-digits="2"
                :disabled="onMenu === 'retur' && editMode"
                @keydown.enter="newInput"
                @focus="selectAllText('tax')"
              />
              <span class="p-inputgroup-addon">%</span>
            </div>
          </div>

          <div
            :class="
              onMenu === 'receive'
                ? 'field col-4 md:col-4'
                : 'field col-6 md:col-6'
            "
          >
            <label>DISC.REG</label>
            <div class="p-inputgroup flex-1">
              <span v-if="form.disc_reg > 100" class="p-inputgroup-addon">
                Rp
              </span>
              <InputNumber
                ref="disc_reg"
                v-model.number="form.disc_reg"
                :class="{
                  'p-invalid': v$.form.disc_reg.$invalid && submitted,
                }"
                locale="id-ID"
                :min="0"
                :minFractionDigits="0"
                :maxFractionDigits="2"
                class="w-full"
                input-class="w-full"
                :disabled="onMenu === 'retur' && editMode"
                @focus="selectAllText('disc_reg')"
                @keydown.enter="enterDiscReg"
                @input="form.disc_reg = $event.value"
              />
              <span v-if="form.disc_reg <= 100" class="p-inputgroup-addon">
                %
              </span>
            </div>
            <small
              v-if="
                (v$.form.disc_reg.$invalid && submitted) ||
                v$.form.disc_reg.$pending.$response
              "
              class="p-error"
            >
              <span v-if="v$.form.disc_reg.required.$invalid">
                {{ v$.form.disc_reg.required.$message }}
              </span>
              <span v-else> {{ v$.form.disc_reg.numeric.$message }}</span>
            </small>
          </div>
          <div
            :class="
              onMenu === 'receive'
                ? 'field col-4 md:col-4'
                : 'field col-6 md:col-6'
            "
          >
            <label>DISC.EXT</label>
            <div class="p-inputgroup flex-1">
              <span v-if="form.disc_ext > 100" class="p-inputgroup-addon">
                Rp
              </span>
              <InputNumber
                ref="disc_ext"
                v-model.number="form.disc_ext"
                :class="{
                  'p-invalid': v$.form.disc_ext.$invalid && submitted,
                }"
                :min="0"
                locale="id-ID"
                :minFractionDigits="0"
                :maxFractionDigits="2"
                class="w-full"
                input-class="w-full"
                :disabled="onMenu === 'retur' && editMode"
                @focus="selectAllText('disc_ext')"
                @keydown.enter="enterDiscExt"
                @input="form.disc_ext = $event.value"
              />
              <span v-if="form.disc_ext <= 100" class="p-inputgroup-addon">
                %
              </span>
            </div>
            <small
              v-if="
                (v$.form.disc_ext.$invalid && submitted) ||
                v$.form.disc_ext.$pending.$response
              "
              class="p-error"
            >
              <span v-if="v$.form.disc_ext.required.$invalid">
                {{ v$.form.disc_ext.required.$message }}
              </span>
              <span v-else> {{ v$.form.disc_ext.numeric.$message }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        @click="$emit('close')"
        class="p-button-text mr-2"
      />
      <Button
        :loading="loading"
        :label="editMode ? 'Ubah' : 'Tambahkan'"
        icon="pi pi-check"
        @click="handleSubmit(!v$.$invalid)"
      />
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, numeric } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import ItemService from '@/services/ItemService'
import ItemSupplierService from '@/services/ItemSupplierService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    qtyB: {
      type: Number,
      default: 0,
    },
    qtyS: {
      type: Number,
      default: 0,
    },
    listSatuan: {
      type: Array,
      default() {
        return []
      },
    },
    suppId: {
      type: String,
      default: '',
    },
    tax: {
      type: Number,
      default: 0,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    isDppMinus: {
      type: Boolean,
      default: false,
    },
    onMenu: {
      type: String,
      default: 'order',
    },
    isFormSuccess: {
      type: Boolean,
      default: false,
    },
    isHideCheckBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemSupplierService: null,
      itemService: null,
      submitted: false,
      released_item: false,
      listItems: [],
      listItemSuppliers: [],
      selectedItem: [],
      query: null,
    }
  },
  created() {
    this.itemService = new ItemService()
    this.itemSupplierService = new ItemSupplierService()
  },
  async mounted() {
    this.selectedItem = this.form.item_name
  },
  watch: {
    isFormSuccess: function () {
      if (this.isFormSuccess === true) {
        this.resetFrom()
        this.$emit('changeFormSuccess', this.isFormSuccess)
      }
    },
  },
  computed: {
    form() {
      return this.item
    },
    listSatuanItem() {
      return this.listSatuan.filter(
        (item) => item.sat === this.form.unit_s || item.sat === this.form.unit_b
      )
    },
    maxQtyS() {
      if (this.onMenu === 'retur') {
        return this.qtyS
      }
      return this.editMode && this.onMenu !== 'receive' && this.onMenu !== 'order'
        ? this.form.qty_s
        : 999999
    },
    maxQtyB() {
      if (this.onMenu === 'retur') {
        return this.qtyB
      }
      return this.editMode && this.onMenu !== 'receive' && this.onMenu !== 'order'
        ? this.form.qty_b
        : 999999
    },
  },
  methods: {
    resetFrom() {
      this.selectedItem = ''
      this.form.item_id = ''
      this.form.ubox = ''
      this.form.unit_b = ''
      this.form.unit_s = ''
      this.form.cost_per = ''
      this.form.disc_reg = 0
      this.form.disc_ext = 0
      this.form.cost = 0
      this.form.qty_b = 0
      this.form.qty_s = 0
      this.form.tax = this.tax
      this.submitted = false
    },
    newInput() {
      this.handleSubmit(!this.v$.$invalid)
      // this.resetFrom()
      // this.$refs.item.$el.querySelector('input').focus()
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    enterItem() {
      this.$refs.qty_b.$el.querySelector('input').focus()
    },
    enterQtyB() {
      this.$refs.qty_s.$el.querySelector('input').focus()
    },
    enterQtyS() {
      this.$refs.cost.$el.querySelector('input').focus()
    },
    enterCost() {
      this.$refs.cost_per.$el.querySelector('input').focus()
    },
    enterCostPer() {
      this.$refs.disc_reg.$el.querySelector('input').focus()
    },
    enterDiscReg() {
      this.$refs.disc_ext.$el.querySelector('input').focus()
    },
    enterDiscExt() {
      this.$refs.tax.$el.querySelector('input').focus()
    },
    changeQTY() {
      if (this.form.qty_s !== 0) {
        this.form.cost_per = this.form.unit_s
      } else {
        this.form.cost_per = this.form.unit_b
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.$emit('save', { ...this.form })

      if (this.form.qty_b === 0 && this.form.qty_s === 0) {
        this.form.qty_s = this.selectedItem.min_order
        return
      }

      if (this.editMode) {
        this.$emit('close')
      } else {
        if (this.isDppMinus) {
          this.$refs.item.$el.querySelector('input').focus()
        }
      }
    },
    inputSatuan(event) {
      const idx = this.listSatuan.findIndex((item) => item.sat === event.value)
      if (idx !== -1) this.form.ubox = this.listSatuan[idx].qty
    },
    selectItem() {
      this.form.item_id = this.selectedItem.id
      this.form.ubox = this.selectedItem.ubox
      this.form.item_name = this.selectedItem.name
      this.form.item_barcode_1 = this.selectedItem.barcode_1
      this.form.item_sku = this.selectedItem.sku
      this.form.item_mrg = this.selectedItem.std_mrg
      this.form.sku = this.selectedItem.sku
      this.form.unit_b = this.selectedItem.unit_b
      this.form.unit_s = this.selectedItem.unit_s
      this.form.cost_per = this.selectedItem.unit_s
      this.form.cost = this.selectedItem.cost
      this.form.min_order = this.selectedItem.min_order
    },
    searchItem(event) {
      this.itemService
        .getList(event.query)
        .then((res) => {
          this.listItems = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => (this.isLoading = false))
    },
    searchItemSuppliers(event) {
      this.form.item_id = ''
      this.itemSupplierService
        .getList(this.suppId, event.query)
        .then((res) => {
          this.listItemSuppliers = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Item Supplier', this)
        })
        .finally(() => (this.isLoading = false))
      this.query = event.query
      this.isLoading = false
    },
    OnCheckClick() {
      // for casting active checkbox checked value to int
      this.released_item = this.released_item == true ? false : true
      this.resetFrom()
      this.$refs.item.$el.querySelector('input').focus()
    },
  },
  validations() {
    return {
      form: {
        item_id: {
          required: helpers.withMessage(
            'Items harus dipilih dari list.',
            required
          ),
        },
        unit_b: {
          required: helpers.withMessage('Satuan Besar harus diisi.', required),
        },
        qty_b: {
          required: helpers.withMessage('QTY(B) harus diisi.', required),
        },
        qty_s: {
          required: helpers.withMessage('QTY(S) harus diisi.', required),
        },
        cost: {
          required: helpers.withMessage('Harga satuan harus diisi.', required),
          numeric: helpers.withMessage(
            'Harga satuan harus harus diisi dengan angka.',
            numeric
          ),
        },
        disc_reg: {
          required: helpers.withMessage('Disc reg harus diisi.', required),
          numeric: helpers.withMessage(
            'Disc reg harus harus diisi dengan angka.',
            numeric
          ),
        },
        disc_ext: {
          required: helpers.withMessage('Disc ext harus diisi.', required),
          numeric: helpers.withMessage(
            'Disc ext harus harus diisi dengan angka.',
            numeric
          ),
        },
      },
    }
  },
}
</script>
